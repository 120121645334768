import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { UpdateStatus } from '../../app/projects/models/update-status';
import { ProjectAPI } from '../../app/projects/project.api';
import { Project } from '@/common/models/project';
import { Tracker } from '../analytics/tracker';

export class ProjectStore {
    private static readonly statusCheckTimeout: number = process.env.NODE_ENV === 'development' ? 2000 : 2000;
    private nextProdStatusCheckCBs: (() => void)[] = [];
    private prodAbortController: AbortController;
    private draftAbortController: AbortController;
    private prodCheckTimeout: number;
    private draftCheckTimeout: number;

    @observable private _projects: IObservableArray<Project> = observable([]);

    @observable updateStatus: UpdateStatus = observable({finished: false, message: '', success: true});
    @observable prevUpdateStatus: UpdateStatus = observable({finished: false, message: '', success: true});
    @observable updateStatusProd: UpdateStatus = observable({finished: false, message: '', success: true});
    @observable prevUpdateStatusProd: UpdateStatus = observable({finished: false, message: '', success: true});
    @observable choosenProject: Project = new Project(1);

    constructor() {
        makeObservable(this);
    }

    @computed
    get projects() {
        if (!this._projects) return [];
        return this._projects.slice().sort((a, b) => {
            if (a.name > b.name) return 1;
            if (a.name < b.name) return -1;
            return 0
        });
    }

    @computed
    get isUpdateNow() {
        return !this.updateStatus.finished;
    }

    setProjects(projects: Project[]) {
        this._projects.replace(projects);
    }

    onNextProdStatusCheck(cb: () => void) {
        this.nextProdStatusCheckCBs.push(cb);
    }

    @action.bound
    async updateProject(project: Project) {
        const updated = await ProjectAPI.updateProject(project);
        const cached = this._projects.find(_project => _project.id === updated.id);
        Object.assign(cached, updated);
        return updated;
    }

    @action.bound
    appendProject = (project: Project) => {
        this._projects.push(project);
        return project;
    }

    @action.bound
    async createProject(name: string, templateProjectId?: number): Promise<Project> {
        try {
            const project = await ProjectAPI.createProject(name, templateProjectId);
            this._projects.push(project);
            return project;
        } catch (e) {
            console.error('Error with add project');
        }
    }

    @action.bound
    async importProject(file: File) {
        try {
            const project = await ProjectAPI.importProject(file);
            this._projects.push(project);
            return project;
        } catch (e) {
            // ???: Проверить, что это за ошибка
            // @ts-ignore
            if (e.status_code === 400) {
                // @ts-ignore
                throw e.dump;
            }
        }
    }

    async updateStoreCurrentProject() {
        this.choosenProject = await ProjectAPI.getProject(this.choosenProject.id);
    }

    @action.bound
    async chooseProject(projectId: number) {
        const project = this._projects.find(p => p.id === projectId) || this._projects[0];
        if (!project) {
            return;
        }

        // if project fetch from api, not from user object
        if (project.current_version) {
            this.choosenProject = project;
        } else {
            const fetchedProject = await ProjectAPI.getProject(project.id);
            Object.assign(project, fetchedProject);
            this.choosenProject = project;
        }

        Tracker.currentProjectName = this.choosenProject.name;
        Tracker.currentProjectId = this.choosenProject.id;

        this.clearTimeouts();
        this.startStatusChecker();
    }

    clearTimeouts() {
        window.clearTimeout(this.draftCheckTimeout);
        window.clearTimeout(this.prodCheckTimeout);
        if (this.draftAbortController) {
            this.draftAbortController.abort();
            this.draftAbortController = null;
        }
        if (this.prodAbortController) {
            this.prodAbortController.abort();
            this.prodAbortController = null;
        }
    }

    /**
     * @return {number} index to switch after remove
     * @param project
     */
    async removeProject(project: Project): Promise<Project> {
        const removedIndex = this._projects.findIndex(_project => _project.id === project.id);
        const removedIndexSorted = this.projects.findIndex(_project => _project.id === project.id);
        const switchedProjectSorted = this.projects[removedIndexSorted === 0 ? removedIndexSorted + 1 : removedIndexSorted - 1];
        const switchedProject = this._projects.find(_project => _project.id === switchedProjectSorted.id)!;
        await ProjectAPI.removeProject(project);
        this._projects.splice(removedIndex, 1);
        return switchedProject;
    }

    @action.bound
    async publish(project: Project) {
        await ProjectAPI.publish(project);
        await this.updateStoreCurrentProject();
    }

    @action.bound
    getProjectVersion() {
        return ProjectAPI.getProjectVersions(this.choosenProject);
    }

    @action.bound
    private async checkProdProjectStatus() {
        this.prodAbortController = new AbortController();
        this.prevUpdateStatusProd = this.updateStatusProd;
        try {
            this.updateStatusProd = await ProjectAPI.getProjectProdStatus(
                this.choosenProject,
                this.prodAbortController.signal
            );

            if (this.nextProdStatusCheckCBs.length) {
                this.nextProdStatusCheckCBs.forEach(fn => fn());
                this.nextProdStatusCheckCBs.length = 0;
            }
        } catch (e) {
            console.error(e);
        }

        this.prodCheckTimeout = window.setTimeout(this.checkProdProjectStatus, ProjectStore.statusCheckTimeout);
    }

    @action.bound
    private async checkDraftProjectStatus() {
        this.draftAbortController = new AbortController();
        this.prevUpdateStatus = this.updateStatus;
        try {
            const updateStatus = await ProjectAPI.getProjectStatus(this.choosenProject, this.draftAbortController.signal);
            runInAction(() => {
                this.updateStatus = updateStatus;
            });
        } catch (e) {
            console.error(e);
        }
        this.draftCheckTimeout = window.setTimeout(this.checkDraftProjectStatus, ProjectStore.statusCheckTimeout);

    }

    @action.bound
    private startStatusChecker(): void {
        this.checkDraftProjectStatus();
        this.checkProdProjectStatus();
    }
}
