import _ from 'lodash';
import { toJS } from 'mobx';
import { Mapper } from './Mapper';
import { ScriptDTO } from '../dtos/script.dto';
import { Node } from '../models/Node';
import { Script } from '../models/Script';
import { Flow } from '../models/Flow';
import { FlowDTO } from '../dtos/flow.dto';
import { StateDTO } from '../dtos/state.dto';
import { State } from '../models/State';
import { Reaction } from '../models/reactions/Reaction';
import { Trigger } from '../models/triggers/Trigger';
import {
    IntentTriggerParamsDTO,
    Triggers
} from '../dtos/trigger.dto';
import { IntentTrigger } from '../models/triggers/IntentTrigger';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { AllowOutTrigger } from '../models/triggers/AllowOutTrigger';
import { FallbackTrigger } from '../models/triggers/FallbackTrigger';
import {
    BranchDTO,
    makeAllEntitiesBranch, makeFailureBranch,
    makeNoEntityBranch,
    makeSuccessBranch,
    makeTrueBranch
} from '../dtos/branch.dto';
import { ReactionParamsDTO, ReactionsDTO, SysIfReactionParamsDTO } from '../dtos/reaction.dto';
import { ReactionsFactory, ReactionsFactoryBuildParam } from '../factories/ReactionsFactory';
import { TriggersFactory, TriggersFactoryTriggerParams } from '../factories/TriggersFactory';
import { Branch } from '../models/Branch';
import { AudioTrigger } from '../models/triggers/AudioTrigger';
import { FileTrigger } from '../models/triggers/FileTrigger';
import { ImageTrigger } from '../models/triggers/ImageTrigger';
import { LocationTrigger } from '../models/triggers/LocationTrigger';
import { StartTrigger } from '../models/triggers/StartTrigger';
import { StickerTrigger } from '../models/triggers/StickerTrigger';
import { VideoTrigger } from '../models/triggers/VideoTrigger';
import { TextReaction } from '../models/reactions/TextReactions';
import { AudioReaction } from '../models/reactions/AudioReaction';
import { VideoReaction } from '../models/reactions/VideoReaction';
import { FileReaction } from '../models/reactions/FileReaction';
import { ImageReaction } from '../models/reactions/ImageReaction';
import { ButtonsReaction } from '../models/reactions/ButtonsReaction';
import { TerminateReaction } from '../models/reactions/TerminateReaction';
import { ResetReaction } from '../models/reactions/ResetReaction';
import { RepeatReaction } from '../models/reactions/RepeatReaction';
import { JumpToReaction } from '../models/reactions/JumpToReaction';
import { SnippetReaction } from '../models/reactions/SnippetReaction';
import { QuickReplies } from '../models/QuickReply';
import { IntentButtonParams } from '../models/Button';
import { FileLikeReaction } from '../models/reactions/FileLikeReaction';
import { LocationReaction } from '../models/reactions/LocationReaction';
import { QuickRepliesDTO } from '../dtos/button.dto';
import { SysIfReaction } from '../models/reactions/SysIfReaction';

export class MapperFlow0_1 extends Mapper {
    fromDTO(dto: ScriptDTO): Script {
        const script = new Script(dto.script_group_id, dto.name,
            dto.type, this.parseFlow(dto.data),
            ('is_active' in dto) ? dto.is_active : true, dto.date_created ? new Date(dto.date_created) : new Date());
        if (dto.id) {
            script.id = dto.id;
        }

        return script;
    }

    toDTO(script: Script): ScriptDTO {
        return {
            id: script.id,
            script_group_id: script.groupId,
            name: script.name,
            type: script.type,
            data: this.stringifyFlow(script.data),
            is_active: script.isActive,
            date_created: script.dateCreated.toISOString(),
        }
    }

    private nextToDTO(next: Node): StateDTO | ReactionsDTO {
        return State.is(next)
            ? this.stringifyState(next as State)
            : this.stringifyReaction(next as Reaction);
    }

    private stringifyDefaultBranch(after: string, child: Node): BranchDTO {
        const next = this.nextToDTO(child);
        return makeTrueBranch(`After ${after}`, next);
    }

    private stringifyFlow(flow: Flow): FlowDTO {
        return {
            flow: flow.state ? this.stringifyState(flow.state)
                : flow.reaction ? this.stringifyReaction(flow.reaction!) : null,
        };
    }

    private stringifyState(state: State): StateDTO {
        return {
            state: {
                id: state.id,
                name: state.name,
                triggers: state.fallback ? [...state.triggers, state.fallback].map(this.stringifyTrigger.bind(this)) :
                    state.triggers.map(this.stringifyTrigger.bind(this))
            },
        };
    }

    private triggerTypeMap: Record<string, typeof Trigger> = {
        'intent': IntentTrigger,
        'entity': EntityTrigger,
        'out': AllowOutTrigger,
        'audio': AudioTrigger,
        'fallback': FallbackTrigger,
        'file': FileTrigger,
        'image': ImageTrigger,
        'location': LocationTrigger,
        'start': StartTrigger,
        'sticker': StickerTrigger,
        'video': VideoTrigger,
    };


    private getTriggerType(trigger: Trigger): string {
        const keys = Object.keys(this.triggerTypeMap);
        for (const key of keys) {
            if (this.triggerTypeMap[key].is(trigger)) {
                return key;
            }
        }

        throw new Error('Mapper.flow0_1 Bad type of trigger: ' + trigger);
    }

    private static stringifySysIfReactionParams(reaction: SysIfReaction): SysIfReactionParamsDTO {
        let mapper = (value: any) => value?.toString();

        const rules = reaction.rules.map((rule) => {
            if ('typeOfValue' in rule) {

                if (rule.typeOfValue === 'number') {
                    mapper = (value: any) => parseFloat(value);
                } else if (rule.typeOfValue === 'boolean') {
                    mapper = (value: any) => value?.toString().toLowerCase() === 'true';
                }

                rule.value = Array.isArray(rule.value) ? rule.value.map(mapper) : mapper(rule.value);
            } else if ('value' in rule) {
                rule.value = Array.isArray(rule.value) ? rule.value.map(mapper) : mapper(rule.value);
            }

            return rule;
        });

        return {
            '@if': {rules: rules}
        }
    }

    private static stringifyIntentTriggerParams(intentTrigger: IntentTrigger): IntentTriggerParamsDTO {
        const intentIds = intentTrigger.intents.map(i => i.id);
        const entitySettings = intentTrigger.entitySettings.map(es => ({
            entity_id: es.entity.id,
            extraction: es.settings,
        }));

        return {
            intent_ids: intentIds,
            entities_settings: entitySettings,
        }
    }

    private getTriggerBranches(trigger: Trigger): Array<BranchDTO> {
        const type = this.getTriggerType(trigger);
        const after = type.charAt(0).toUpperCase() + type.slice(1);
        switch (type) {
            case 'intent': {
                const it = trigger as IntentTrigger;
                const branches: Array<BranchDTO> = [];
                if (it.next) {
                    const defaultBranch = makeAllEntitiesBranch('All', this.nextToDTO(it.next));
                    branches.push(defaultBranch);
                }
                branches.push(...it.noEntityBranches.filter(n => n.next).map(n => {
                    return makeNoEntityBranch(
                        n.entity.name ? `No ${n.entity.name}` : n.name,
                        n.entity,
                        n.next ? this.nextToDTO(n.next) : undefined
                    );
                }));

                return branches;
            }
            case 'entity': {
                const entityTrigger = trigger as EntityTrigger;
                const branches: Array<BranchDTO> = [];
                if (entityTrigger.next) {
                    const defaultBranch = this.stringifyDefaultBranch(after, entityTrigger.next);
                    branches.push(defaultBranch);
                }

                if (entityTrigger.noEntityNext && entityTrigger.entity) {
                    branches.push(makeNoEntityBranch(
                        `No ${entityTrigger.entity!.name}`,
                        entityTrigger.entity,
                        this.nextToDTO(entityTrigger.noEntityNext)));
                }

                return branches;
            }
            default:
                if (trigger.next) {
                    return [this.stringifyDefaultBranch(after, trigger.next)];
                } else {
                    return [];
                }
        }
    }

    private stringifyTrigger(trigger: Trigger): Triggers {
        const type = this.getTriggerType(trigger);
        switch (type) {
            case 'intent': {
                return {
                    type: 'intent',
                    params: MapperFlow0_1.stringifyIntentTriggerParams(trigger as IntentTrigger),
                    branches: this.getTriggerBranches(trigger),
                }
            }
            case 'entity': {
                const entityTrigger = trigger as EntityTrigger;
                return {
                    type: 'entity',
                    params: entityTrigger.entity ? {entity_id: entityTrigger.entity.id!} : null,
                    branches: this.getTriggerBranches(trigger),
                }
            }
            case 'out': {
                const allowTrigger = trigger as AllowOutTrigger;
                return {
                    type: 'out',
                    params: {allow: allowTrigger.allow},
                    branches: this.getTriggerBranches(trigger),
                }
            }
            default: {
                // TODO: think with it
                return {
                    // @ts-ignore
                    type,
                    params: null,
                    branches: this.getTriggerBranches(trigger)
                }
            }
        }
    }

    private reactionTypeMap: Record<string, typeof Reaction> = {
        'text': TextReaction,
        'audio': AudioReaction,
        'video': VideoReaction,
        'file': FileReaction,
        'image': ImageReaction,
        'buttons': ButtonsReaction,
        'terminate': TerminateReaction,
        'reset': ResetReaction,
        'repeat': RepeatReaction,
        'jump': JumpToReaction,
        'snippet': SnippetReaction,
        'location': LocationReaction,
        'if': SysIfReaction,
    };

    private getReactionType(reaction: Reaction): string {
        const keys = Object.keys(this.reactionTypeMap);
        for (const key of keys) {
            if (this.reactionTypeMap[key].is(reaction)) {
                return key;
            }
        }

        throw new Error('Bad type of reaction: ' + reaction);
    }

    private static stringifyQuickReplies(quickReplies: QuickReplies): QuickRepliesDTO {
        return quickReplies.buttons ? quickReplies.buttons.map(b => {
            return {
                type: b.type,
                title: b.title,
                params: b.params ? {
                    ...b.params,
                    intent_id: (b.params as IntentButtonParams).intent_id,
                    intentId: undefined
                } : {},
            };
        }) : [];
    }

    private static stringifyReactionParams(reaction: Reaction): ReactionParamsDTO {
        switch (reaction.type) {
            case TextReaction.type: {
                const textReaction = reaction as TextReaction;
                return {
                    'text': {
                        '@mustache':
                            {
                                '@randomChoice': toJS(textReaction.texts)
                            }
                    },
                    'tts': textReaction.tts,
                    'quick_replies': this.stringifyQuickReplies(textReaction.quickReplies),
                };
            }
            case SysIfReaction.type: {
                const sysIfReaction = reaction as SysIfReaction;
                return {
                    ...MapperFlow0_1.stringifySysIfReactionParams(sysIfReaction)
                }
            }
            case JumpToReaction.type: {
                const jumpToReaction = reaction as JumpToReaction;
                return {
                    next: jumpToReaction.jumpNext,
                };
            }
            case ImageReaction.type:
            case AudioReaction.type:
            case VideoReaction.type:
            case FileReaction.type: {
                const fileLikeReaction = reaction as FileLikeReaction;
                return {
                    url: fileLikeReaction.url,
                    caption: fileLikeReaction.caption,
                    quick_replies: this.stringifyQuickReplies(fileLikeReaction.quickReplies),
                }
            }
            case LocationReaction.type: {
                const locationReaction = reaction as LocationReaction;
                return {
                    'lat': locationReaction.lat,
                    'lon': locationReaction.lon,
                    'name': locationReaction.name,
                    'quick_replies': this.stringifyQuickReplies(locationReaction.quickReplies),
                }
            }
            case ButtonsReaction.type: {
                const buttonsReaction = reaction as ButtonsReaction;
                return {
                    'text': buttonsReaction.text,
                    buttons: this.stringifyQuickReplies(buttonsReaction.buttons),
                };
            }
            case SnippetReaction.type: {
                const snippetReaction = reaction as SnippetReaction;
                return {
                    'snippet_id': snippetReaction.snippetId,
                    'fail': snippetReaction.fail
                };
            }

            case RepeatReaction.type: {
                const repeatReaction = reaction as RepeatReaction;
                return {
                    'text': repeatReaction.text,
                    'tts': repeatReaction.tts
                }
            }
            default:
                return {};
        }
    }

    private getReactionBranches(reaction: Reaction): Array<BranchDTO> {
        switch (reaction.type) {
            case SysIfReaction.type:
            case SnippetReaction.type: {
                const snippetReaction = reaction as SnippetReaction | SysIfReaction;
                if (snippetReaction.fail) {
                    const branches = [];
                    if (snippetReaction.next) {
                        branches.push(makeSuccessBranch('Success', this.nextToDTO(snippetReaction.next)));
                    }

                    if (snippetReaction.nextFail) {
                        branches.push(makeFailureBranch('Failure', this.nextToDTO(snippetReaction.nextFail)));
                    }
                    return branches
                } else {
                    return reaction.next ? [this.stringifyDefaultBranch(reaction.name, reaction.next)] : [];
                }
            }
            default:
                return reaction.next ? [this.stringifyDefaultBranch(reaction.name, reaction.next)] : [];
        }

    }

    private stringifyReaction(reaction: Reaction): ReactionsDTO {
        return {
            reaction: {
                id: reaction.id,
                name: reaction.name,
                // @ts-ignore
                type: this.getReactionType(reaction),
                params: MapperFlow0_1.stringifyReactionParams(reaction),
                branches: this.getReactionBranches(reaction),
            }
        };
    }

    private parseNext(next: StateDTO | ReactionsDTO, parent: Node | null): State | Reaction {
        if ((next as ReactionsDTO).reaction) {
            return this.parseReaction(next as ReactionsDTO, parent);
        } else {
            return this.parseState(next as StateDTO, parent);
        }
    }

    private parseBranch(branchDTO: BranchDTO, parent: Node | null): Branch {
        return {
            name: branchDTO.name,
            condition: branchDTO.condition,
            next: branchDTO.next ? this.parseNext(branchDTO.next, parent) : null,
        }
    }

    parseFlow(dto: FlowDTO): Flow {
        const flow = new Flow();
        if (dto.flow) {
            flow.next = (dto.flow as StateDTO).state ? this.parseState(dto.flow as StateDTO, flow)
                : this.parseReaction(dto.flow as ReactionsDTO, flow);
        }
        return flow;
    }

    private parseState(stateDTO: StateDTO, parent: Node | null): State {
        const stateFromDTO = stateDTO.state;
        const state = new State(stateFromDTO.id, stateFromDTO.name, []);
        state.parent = parent;
        const triggers = stateFromDTO.triggers.concat();
        const fallbackIndex = triggers.findIndex(t => t.type === 'fallback');
        if (fallbackIndex >= 0) {
            const [fallbackTrigger] = triggers.splice(fallbackIndex, 1);
            state.fallback = this.parseTrigger(fallbackTrigger, state);
        }
        state.triggers = triggers.map((triggerDTO) => this.parseTrigger(triggerDTO, state));
        return state;
    }

    private static parseReactionParams({reaction}: ReactionsDTO): ReactionsFactoryBuildParam {
        switch (reaction.type) {
            case 'text': {
                const texts = _.get(reaction.params, ['text', '@mustache', '@randomChoice']) ||
                    _.get(reaction.params, ['text', '@randomChoice', '@mustache']) || [];
                return {
                    texts,
                    tts: reaction.params.tts,
                    // TODO: fix this
                    // @ts-ignore
                    quickReplies: new QuickReplies(reaction.params.quick_replies),
                };
            }
            case 'audio':
            case 'file':
            case 'video':
            case 'image': {
                return {
                    url: reaction.params.url,
                    caption: reaction.params.caption,
                    // TODO: fix this
                    // @ts-ignore
                    quickReplies: new QuickReplies(reaction.params.quick_replies),
                };
            }
            case 'buttons': {
                return {
                    text: reaction.params.text,
                    buttons: reaction.params.buttons,
                };
            }
            case 'jump': {
                return {
                    next: reaction.params.next
                };
            }
            case 'location': {
                return {
                    lat: reaction.params.lat,
                    lon: reaction.params.lon,
                    name: reaction.params.name,
                    // TODO: fix this
                    // @ts-ignore
                    quickReplies: new QuickReplies(reaction.params.quick_replies),
                };
            }
            case 'snippet': {
                return {
                    snippet_id: reaction.params.snippet_id,
                    fail: reaction.params.fail,
                };
            }
            case 'if': {
                console.log('pams', reaction.params);
                return {
                    rules: reaction.params['@if']?.rules ?? [],
                    fail: reaction.branches.length > 1,
                };
            }
            case 'repeat': {
                return {
                    text: reaction.params.text,
                    tts: reaction.params.text,
                };
            }
            default: {
                return {};
            }
        }
    }

    private parseReaction(reactionsDTO: ReactionsDTO, parent: Node | null): Reaction {
        const resultReaction = ReactionsFactory.build({
            type: reactionsDTO.reaction.type,
            id: reactionsDTO.reaction.id,
            name: reactionsDTO.reaction.name,
            params: MapperFlow0_1.parseReactionParams(reactionsDTO),
            parent,
            branches: reactionsDTO.reaction.branches
                ? reactionsDTO.reaction.branches.map(b => this.parseBranch(b, null))
                : [],
        });

        resultReaction.parent = parent;
        return resultReaction;
    }

    private static triggerDTOParamsToTriggerFactoryParams(triggerDTO: Triggers): TriggersFactoryTriggerParams {
        switch (triggerDTO.type) {
            case 'intent': {
                return {
                    intentIds: triggerDTO.params.intent_ids,
                    entitySettings: triggerDTO.params.entities_settings && triggerDTO.params.entities_settings.map((es) => {
                        return ({
                            entityId: es.entity_id,
                            extraction: es.extraction
                        })
                    })
                }
            }
            case 'entity': {
                return triggerDTO.params ? {
                    entityId: triggerDTO.params.entity_id,
                } : null
            }
            case 'out': {
                return {
                    allow: triggerDTO.params.allow || 'out',
                }
            }
            default: {
                return {};
            }
        }
    }

    private parseTrigger(triggerDTO: Triggers, parent: State): Trigger {
        const trigger = TriggersFactory.build({
            parent,
            params: MapperFlow0_1.triggerDTOParamsToTriggerFactoryParams(triggerDTO),
            type: triggerDTO.type,
            branches: triggerDTO.branches ? triggerDTO.branches.map(b => this.parseBranch(b, null)) : [],
        });
        trigger.parent = parent;
        return trigger;
    }
}
