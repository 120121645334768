import { action, computed, IObservableArray, makeObservable, observable } from 'mobx';
import { QAAPI } from './qa.api';
import { QA } from './models/qa';
import { RootStore } from '@/core/stores/root.store';
import { Group } from '@/common/models/group';
import { downloadFile } from '@/common/utils/download-file';
import { Pagination } from '@/common/models/pagination';
import { LoadableStore } from '@/common/store';
import { DragSidemenuItem } from '@/app/components/sidebar';
import { Tracker } from '@/core/analytics/tracker';
interface PaginationQa {
    end?: boolean;
}
export class QAStore extends LoadableStore {
    qaPageSize = 1000;
    @observable _qas: IObservableArray<QA> = observable([]);
    @observable _groups: IObservableArray<Group> = observable([]);
    @observable isLoaded: boolean = false;
    @observable _paginationGroups: Record<number, Pagination & PaginationQa> = {};

    constructor(private rootStore: RootStore) {
        super();
        makeObservable(this);
    }

    @computed get paginationGroups() {
        return this._paginationGroups;
    }

    @computed get qas() {
        return this._qas;
    }

    @computed get groups() {
        return this._groups;
    }

    @computed
    get currentProject() {
        return this.rootStore.projectStore.choosenProject;
    }

    @computed
    get defaultGroup() {
        return this.groups[0];
    }

    @action.bound
    clearStore() {
        this._qas.replace([]);
        this._paginationGroups = {};
        this._groups.replace([]);
        this.isLoaded = false;
        this._load();
    }

    static emptyQAWithAnswer(answerText: string): QA {
        return {
            answers: [{ text: answerText }],
            questions: [],
            qa_group_id: 0,
            is_active: true,
        };
    }

    @action.bound
    async _load(loadAll?: boolean) {
        const groups = await QAAPI.getGroups(this.currentProject);
        this._groups.replace(groups);
        this._paginationGroups = {};
        if (groups.length) {
            const qas = await QAAPI.getAll(
                this.currentProject,
                {
                    page: 1,
                    per_page: this.qaPageSize,
                },
                !loadAll && {
                    qa_group_id: groups[0].id!,
                },
            );
            this._qas.replace(qas.results);
            this._paginationGroups[groups[0].id!] = {
                page: 2,
                per_page: this.qaPageSize,
                end: qas.current_page === qas.page_count,
            };
        }

        this.isLoaded = true;
    }

    @action.bound
    async saveQA(qa: QA): Promise<QA> {
        if (qa.id) {
            return this.updateQA(qa);
        } else {
            return this.createQA(qa);
        }
    }

    @action.bound
    async patchQA(qa: any): Promise<any> {
        return QAAPI.patchQA(this.currentProject, qa);
    }

    isEndPagination(group: Group) {
        if (!this._paginationGroups[group.id!]) {
            return true;
        }
        return this._paginationGroups[group.id!].end;
    }

    @action.bound
    async createQA(qa: QA) {
        if (!this.groups.length) {
            const group = await QAAPI.createGroup(this.currentProject, {
                name: 'Default group',
            });
            this._paginationGroups[group.id!] = {
                end: true,
                page: 1,
                per_page: this.qaPageSize,
            };
            this.groups.push(group);
        }

        const qaWithGroup = Object.assign({}, qa, {
            name: qa.name,
            qa_group_id: this.defaultGroup.id!,
        });

        const _qa = await QAAPI.createQA(this.currentProject, qaWithGroup);
        this._qas.push(_qa);
        return _qa;
    }

    @action.bound
    async updateQA(qa: QA) {
        const _qa = await QAAPI.updateQA(this.currentProject, qa);
        const updatedQa = this.qas.find(qaItem => qaItem.id === qa.id);
        if (updatedQa) Object.assign(updatedQa, _qa);
        return _qa;
    }

    getQAById(qaId: number) {
        return this._qas.find(_qa => _qa.id === qaId);
    }

    getQAWithoutPush(qaId: number) {
        return QAAPI.getQAById(this.currentProject, qaId);
    }

    async getQAByIdAsync(qaId: number) {
        const qa = await QAAPI.getQAById(this.currentProject, qaId);
        this._qas.push(qa);
        return qa;
    }

    getGroupById(groupId: number) {
        return this._groups.find(_group => _group.id === groupId);
    }

    @action.bound
    async removeQA(qa: QA) {
        await QAAPI.deleteQA(this.currentProject, qa);
        const index = this.qas.findIndex(_qa => _qa.id === qa.id);
        this.qas.splice(index, 1);
    }

    @action.bound
    async createQAgroupWithQa(name = `New group`, qasId: number[]) {
        const group = await QAAPI.createGroup(this.currentProject, { name });
        this._groups.push(group);
        this.paginationGroups[group.id!] = { end: true, per_page: 0, page: 1 };
        const updateQA: Promise<QA>[] = [];
        qasId.forEach(id => {
            const qa = this._qas.find(_qa => _qa.id === id);
            if (qa) {
                qa.qa_group_id = group.id!;
                updateQA.push(this.updateQA(qa));
            }
        });
        await Promise.all(updateQA);
        return group;
    }

    @action
    async saveGroup(group: Group) {
        const updatedGroup = await QAAPI.updateGroup(this.currentProject, group);
        const mergeGroup = this.getGroupById(group.id!);
        if (mergeGroup) {
            mergeGroup.name = updatedGroup.name;
        }
    }

    @action async removeGroup(groupId: number) {
        await QAAPI.removeGroup(this.currentProject, groupId);
        const index = this.groups.findIndex(_group => _group.id === groupId);
        this.groups.splice(index, 1);
    }

    @action async getMoreElements(groupId: number) {
        if (!this.paginationGroups[groupId]) {
            this.paginationGroups[groupId] = {
                page: 1,
                per_page: this.qaPageSize,
            };
        }
        try {
            const qas = await QAAPI.getAll(this.currentProject, this.paginationGroups[groupId], {
                qa_group_id: groupId,
            });
            this._qas.push(
                ...qas.results.filter((qa: QA) => {
                    const _qa = this._qas.find(item => item.id === qa.id);
                    return !_qa;
                }),
            );
            if (qas.current_page === qas.page_count) {
                this.paginationGroups[groupId].end = true;
            } else {
                this.paginationGroups[groupId].page++;
            }
        } catch (e) {
            this.paginationGroups[groupId].end = true;
        }
    }

    @action
    async upload(files: File[], replace: boolean): Promise<void> {
        await QAAPI.uploadQA(this.currentProject, files[0], replace);
        return this.reload();
    }

    @action
    async download(): Promise<void> {
        const response = await fetch(`/api/v1/projects/${this.currentProject.id}/qa-download`, {
            method: 'GET',
        });

        const text = await response.text();

        const file = response.headers.get('content-disposition')!.match('filename="(.*)"');
        const filename = file ? file[1] : 'export.csv';
        downloadFile(filename, text);
    }

    @action
    intentQaSearch(term: string) {
        return QAAPI.intentQaSearch(this.currentProject, term);
    }

    async replaceElementsInGroup(from: DragSidemenuItem, to: DragSidemenuItem) {
        if (from.id === to.id) return;
        if (from.groupId === to.groupId) {
            const group = await this.createQAgroupWithQa(`New group ${this.groups.length + 1}`, [from.id, to.id]);
            Tracker.trackEvent('Group', { Type: 'Create', ObjectId: group.id });
        } else {
            const qa = this.getQAById(from.id);
            if (qa) {
                qa.qa_group_id = to.groupId;
                await this.updateQA(qa);
            }
        }
    }
}
