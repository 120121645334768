import { makeObservable, observable } from 'mobx';
import { TemplateProjectsStore } from '@/app/components/header/store/template-projects.store';
import { Project } from '@/common/models/project';
import { ProjectStore } from '@/core/stores/project.store';
import { AuthAPI } from '@/auth/auth.api';
import { UserStore } from '@/core/stores/user.store';
import { ProjectAPI } from '@/app/projects/project.api';

export class HeaderProjectStore {
    templateProjectsStore = new TemplateProjectsStore();
    @observable isOpenProjects = false;
    @observable currentChoosedProject: Project = new Project(NaN, '');
    @observable publishProcess = false;
    @observable showPublishError = false;
    @observable linkToWidget: string = '';
    @observable isOpenNotActivatedEmailAlert = false;
    @observable isLoadingActivated = false;
    @observable isDisabledPopoverVisible = false;
    @observable isClonedProcess = false;
    constructor(private projectStore: ProjectStore, private user: UserStore) {
        makeObservable(this);
    }

    cloneProject = async (id: number, name: string) => {
        this.isClonedProcess = true;
        try {
            const project = await ProjectAPI.clone(id, name);
            this.projectStore.appendProject(project);
            this.isClonedProcess = false;
            return project;
        } catch(e) {
            console.error('eee', e);
        }
        this.isClonedProcess = false;
    }

    toggle = () => {
        this.isOpenProjects = !this.isOpenProjects;
    };

    onActivatedClick = async () => {
        this.isLoadingActivated = true;
        await AuthAPI.sendActivationMail(this.user.userInfo!.email);
        this.isLoadingActivated = false;
        this.isOpenNotActivatedEmailAlert = !this.isOpenNotActivatedEmailAlert;
    };

    toggleActivatedEmailModal = () => {
        this.isOpenNotActivatedEmailAlert = !this.isOpenNotActivatedEmailAlert;
    };

    updateChoosedProject(project?: Project) {
        this.currentChoosedProject = project || this.projectStore.choosenProject;
    }
}
