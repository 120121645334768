import React, { FC, useEffect, useState } from 'react';
import {
    Modal
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import CrossIcon from 'mdi-react/CloseIcon';

import cn from './HeaderProject.module.scss';
import { HeaderCreateProject } from './HeaderCreateProject';
import { Project } from '@/common/models/project';
import { inject, observer, Provider } from 'mobx-react';
import { AppStore } from '../../stores/app.store';
import { ProjectStore } from '@/core/stores/project.store';
import { UserStore } from '@/core/stores/user.store';
import { ErrorsStore } from '@/core/stores/errors.store';
import { CustomAlert } from '@/common/components/alert/CustomAlert';
import { Tracker } from '@/core/analytics/tracker';
import { ProfileStore } from '@/app/profile-settings/profile.store';
import { ConnectStore } from '../../connects/connect.store';
import { changePageTitle } from '@/common/utils/change-page-title';
import { SearchItemsComponent } from '@/app/components/header/SearchItems.component';
import { Dropdown, MenuProps, Modal as ModalNew, Form, Input } from 'antd';
import { HeaderProjectStore } from '@/app/components/header/HeaderProject.store';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';


type HeaderProjectProps1 = {
    user?: UserStore;
    profileStore?: ProfileStore;
    appStore?: AppStore;
    projectStore?: ProjectStore;
    errorsStore?: ErrorsStore;
    connectStore?: ConnectStore;
}

export const HeaderProject: FC<HeaderProjectProps1> = inject('appStore', 'projectStore', 'user', 'errorsStore', 'connectStore', 'profileStore')(observer(({
                                                                                                                                                              user,
                                                                                                                                                              profileStore,
                                                                                                                                                              appStore,
                                                                                                                                                              projectStore,
                                                                                                                                                              errorsStore,
                                                                                                                                                              connectStore
                                                                                                                                                          }) => {
    const [headerProjectStore] = useState(() => new HeaderProjectStore(projectStore, user));
    const [isOpenCloneDialog, setIsOpenCloneDialog] = useState(false);
    const [clonedProject, setClonedProject] = useState(null);
    const [clonedProjectName, setClonedProjectName] = useState('');
    const [items, setItems] = useState<MenuProps['items']>([]);
    const [isOpenProjects, setIsOpenProjects] = useState(false);

    const {t} = useTranslation();
    const navigate = useNavigate();

    const projectTitle = projectStore.projects.length === 1 ? 'project_header.projects_one' : 'project_header.projects_many';

    useEffect(() => {
        connectStore.loadChannels();
        headerProjectStore.updateChoosedProject();
        profileStore.getCompany();
    }, []);

    useEffect(() => {
        const menuItems: MenuProps['items'] = [
            {
                label: `${t(projectTitle)} (${projectStore.projects.length})`,
                key: '0',
                disabled: true
            },
            {
                type: 'divider'
            },
            ...projectStore.projects.map((project) => {
                return {
                    label: <div className={cn.dropdownProjectItem}>{project.name} <CopyOutlined onClick={(e) => {
                        e.stopPropagation();
                        setIsOpenProjects(false);
                        startCloneProject(project);
                    }} className={cn.dropdownProjectItemIcon}/></div>,
                    key: project.id.toString(),
                    selected: true,
                    onClick: () => {
                        onSelectProject(project)();
                    }
                }
            }),

        ];

        if (user.permissions.isManageProjects) {
            menuItems.push({
                type: 'divider',
            }, {
                label: t('project_header.add_project'),
                onClick: () => {
                    headerProjectStore.toggle();
                },
                key: projectStore.projects.length + 100,
            })
        }

        setItems(menuItems);
    }, [projectStore.projects]);
    const onSelectProject = (project: Project) => async () => {
        try {
            Tracker.trackEvent('SelectProject', {From: user.currentChoosedProjectId, to: project.id});
            await user.chooseProject(project.id);
            headerProjectStore.updateChoosedProject(project);
            navigate(`/app/${project.id}/flows`);
            appStore.clearApp();
            connectStore!.loadChannels();
            changePageTitle(`Лия - ${project.name}`);
        } catch (e) {
            console.error('Error with selected project', e);
        }
    };

    const onCreateProject = async (project: Project) => {
        await onSelectProject(project)();
    };

    const startCloneProject = (project: Project) => {
        setClonedProject(project);
        setClonedProjectName(`Clone - ${project.name}`);
        setIsOpenCloneDialog(true);
    }

    const cloneProject = async () => {
        const project = await headerProjectStore.cloneProject(clonedProject.id, clonedProjectName);
        handleCancel();
        onSelectProject(project)();
    }

    const handleCancel = () => {
        setIsOpenCloneDialog(false);
        setClonedProject(null);
        setClonedProjectName(null);
    
    }

    return <div className={cn.headerProject}>
        <ModalNew title={`Клонировать проект ${clonedProject?.name}?`}
                  open={isOpenCloneDialog} okButtonProps={{ loading: headerProjectStore.isClonedProcess }} okText={'Клонировать'}
                  cancelText={'Закрыть'} onOk={cloneProject} onCancel={handleCancel}>
            <Form.Item>
                <Input disabled={headerProjectStore.isClonedProcess} value={clonedProjectName}
                       onChange={(e) => setClonedProjectName(e.target.value)}/>
            </Form.Item>
        </ModalNew>

        <Modal
            className={cn.createProjectModalHost}
            contentClassName={cn.createProjectModal}
            backdropClassName={cn.createProjectModalBackdrop}
            isOpen={headerProjectStore.isOpenProjects}
            toggle={headerProjectStore.toggle}
        >
            <Provider templateProjectsStore={headerProjectStore.templateProjectsStore}>
                <HeaderCreateProject onCreateProjectAction={onCreateProject}
                                     closeModal={headerProjectStore.toggle}/>
            </Provider>
        </Modal>
        <CustomAlert
            isOpen={headerProjectStore.isOpenNotActivatedEmailAlert}
            toggle={headerProjectStore.toggleActivatedEmailModal}
            onButtonClick={headerProjectStore.onActivatedClick}
            isLoadingActivated={headerProjectStore.isLoadingActivated}
            title={'project_header.verify'}
            subtitle={'project_header.verify_subtitle'}
            buttonText={'project_header.activation_email'}
        />
        <div className={cn.headerProjectHeader}>
            <Dropdown menu={{items}}
                        open={isOpenProjects}
                        onOpenChange={isOpen => setIsOpenProjects(isOpen)}
                      trigger={['click']}>
                <p className={cn.headerProjectTitle}>
                    {projectStore.choosenProject.name}
                    <ChevronDownIcon className={cn.headerProjectTitleIcon}/>
                </p>
            </Dropdown>
        </div>
        {/*<div className={cn.headerProjectHeader}>*/}
        {/*    <UncontrolledDropdown className={cn.headerProjectUncontroller}>*/}
        {/*        <DropdownToggle*/}
        {/*            className={classnames(cn.headerProjectDropdown, 'icon icon--right')}*/}
        {/*            tag="div"*/}
        {/*            color="primary">*/}
        {/*            <p className={cn.headerProjectTitle}>*/}
        {/*                {projectStore.choosenProject.name}*/}
        {/*                <ChevronDownIcon className={cn.headerProjectTitleIcon}/>*/}
        {/*            </p>*/}
        {/*        </DropdownToggle>*/}
        {/*        <DropdownMenu className={classnames(cn.headerProjectDropdownWrapper, 'dropdown__menu')}>*/}
        {/*            <DropdownItem*/}
        {/*                disabled*/}
        {/*                className={'dropdown-item--title'}*/}
        {/*            >*/}
        {/*                {t(projectTitle)}*/}
        {/*                {projectStore.projects.length > 1 && ` (${projectStore.projects.length})`}*/}
        {/*            </DropdownItem>*/}
        {/*            <DropdownItem divider/>*/}
        {/*            <div className={cn.headerProjectProjectsWrapper}>*/}
        {/*                <>*/}
        {/*                    <DropdownItem disabled className={cn.headerProjectOrgName}>*/}
        {/*                        {profileStore.company && profileStore.company.name ? profileStore.company.name : t('project_header.yours')}*/}
        {/*                        {` ${t('project_header.organization')}`}*/}
        {/*                    </DropdownItem>*/}
        {/*                    {projectStore.projects.map((project) => {*/}
        {/*                        return <DropdownItem key={project.id} onClick={onSelectProject(project)}>*/}
        {/*                            {project.name}*/}
        {/*                        </DropdownItem>*/}
        {/*                    })}*/}
        {/*                </>*/}
        {/*                /!*{this.renderOrganization(profileStore.company, projectStore.projects, true)}*!/*/}
        {/*            </div>*/}
        {/*            <DropdownItem divider/>*/}
        {/*            <DropdownItem onClick={headerProjectStore.toggle} className={'dropdown-item--control'}>*/}
        {/*                <Modal*/}
        {/*                    className={cn.createProjectModalHost}*/}
        {/*                    contentClassName={cn.createProjectModal}*/}
        {/*                    backdropClassName={cn.createProjectModalBackdrop}*/}
        {/*                    isOpen={headerProjectStore.isOpenProjects}*/}
        {/*                    toggle={headerProjectStore.toggle}*/}
        {/*                >*/}
        {/*                    <Provider templateProjectsStore={headerProjectStore.templateProjectsStore}>*/}
        {/*                        <HeaderCreateProject onCreateProjectAction={onCreateProject}*/}
        {/*                                             closeModal={headerProjectStore.toggle}/>*/}
        {/*                    </Provider>*/}
        {/*                </Modal>*/}
        {/*                <PermissionsView permission={Permission.MANAGE_PROJECTS}>*/}
        {/*                    <div className={cn.headerAddProjectButton}>*/}
        {/*                        {t('project_header.add_project')}*/}
        {/*                    </div>*/}
        {/*                </PermissionsView>*/}
        {/*            </DropdownItem>*/}
        {/*        </DropdownMenu>*/}
        {/*    </UncontrolledDropdown>*/}
        {/*</div>*/}

        <SearchItemsComponent/>

        {errorsStore.headerFixedError ?
            <div className={cn.errorPublish}>
                {errorsStore.headerFixedError}
                <div className={cn.errorIcon} onClick={() => errorsStore.removeHeaderError()}><CrossIcon
                    size={14}/></div>
            </div> : ''}
    </div>
}));
