import { action, computed, IObservableArray, observable } from 'mobx';
import { Group } from '../../common/models/group';
import { RootStore } from '../../core/stores/root.store';
import { EntitiesAPI } from './entities.api';
import { EntityExtractor } from './models/entity-extractor';
import { Entity } from './models/entity';
import { LoadableStore } from '../../common/store';
import { Tracker } from '../../core/analytics/tracker';
import { DragSidemenuItem } from '@/app/components/sidebar/models/drag-sidemenu-item';

export class EntityStore extends LoadableStore {
    @observable _entities: IObservableArray<Entity> = observable([]);
    @observable _groups: IObservableArray<Group> = observable([]);
    @observable _extractors: IObservableArray<EntityExtractor> = observable([]);
    @observable isLoaded: boolean;

    constructor(private rootStore: RootStore) {
        super();
        this.isLoaded = false;
    }

    @computed get extractors(): IObservableArray<EntityExtractor> {
        return this._extractors;
    }

    @computed get entities() {
        return this._entities;
    }

    @computed get groups() {
        return this._groups;
    }

    @computed
    get currentProject() {
        return this.rootStore.projectStore.choosenProject;
    }

    @computed
    get defaultGroup() {
        return this.groups[0];
    }

    @action.bound
    clearStore() {
        this._groups.replace([]);
        this._entities.replace([]);
        this.isLoaded = false;
        this._load();
    }

    @action.bound
    async _load() {
        const [groups, entities, extractors] = await Promise.all([
            EntitiesAPI.getGroups(this.currentProject),
            EntitiesAPI.getAll(this.currentProject, {
                page: 1,
                per_page: 100
            }),
            EntitiesAPI.getExtractors(this.rootStore.projectStore.choosenProject.language!)
        ]);

        this._groups.replace(groups);
        this._entities.replace(entities);
        this._extractors.replace(extractors);
        this.isLoaded = true;
    }

    @action.bound
    async saveEntity(entity: Entity): Promise<Entity> {
        if (entity.id) {
            return this.updateEntity(entity);
        } else {
            return this.createEntity(entity);
        }
    }

    @action.bound
    async createEntity(entity: Entity) {
        if (!this.groups.length) {
            const group = await EntitiesAPI.createGroup(this.currentProject, { name: 'Default group' });
            this.groups.push(group);
        }

        const entityWithGroup = Object.assign({}, entity, {
            name: entity.name,
            entity_group_id: this.defaultGroup.id!,
            id: undefined,
        });

        const _entity = await EntitiesAPI.createEntity(this.currentProject, entityWithGroup);
        this._entities.push(_entity);
        return _entity;
    }

    @action.bound
    async updateEntity(entity: Entity) {
        const _entity = await EntitiesAPI.updateEntity(this.currentProject, entity);
        Object.assign(entity, _entity);
        return entity;
    }

    clearEntity = (entity: Entity) => {
        for (const key of Object.keys(entity)) {
            if (key === 'id') {
                continue;
            }

            delete entity[key as keyof Entity];
        }
        
        console.log(Object.keys(entity), Object.values(entity));
    }

    @action.bound
    patchEntity(entity: any) {
        return EntitiesAPI.patchEntity(this.currentProject, entity);
    }



    getEntityById(entityId?: number) {
        return this._entities.find(_entity => _entity.id === entityId);
    }

    getGroupById(groupId: number) {
        return this._groups.find(_group => _group.id === groupId);
    }

    @action.bound
    async removeEntity(entity: Entity) {
        await EntitiesAPI.deleteEntity(this.currentProject, entity);
        const index = this.entities.findIndex(_entity => _entity.id === entity.id);
        this.entities.splice(index, 1);
    }

    @action.bound
    async createGroupWithEntity(name = 'New group', entityIds: number[]) {
        const group = await EntitiesAPI.createGroup(this.currentProject, { name });
        this._groups.push(group);
        const updateEntities: Promise<Entity>[] = [];
        entityIds.forEach(
            id => {
                const entity = this._entities.find(_entity => _entity.id === id);
                if (entity) {
                    entity.entity_group_id = group.id!;
                    updateEntities.push(this.updateEntity(entity));
                }
            }
        );
        await Promise.all(updateEntities);
        return group;
    }

    @action
    async saveGroup(group: Group) {
        const updatedGroup = await EntitiesAPI.updateGroup(this.currentProject, group);
        const mergeGroup = this.getGroupById(group.id!);
        if (mergeGroup) {
            mergeGroup.name = updatedGroup.name;
        }
    }

    @action async removeGroup(groupId: number) {
        await EntitiesAPI.removeGroup(this.currentProject, groupId);
        const index = this.groups.findIndex(_group => _group.id === groupId);
        this.groups.splice(index, 1);
    }

    async replaceElementsInGroup(from: DragSidemenuItem, to: DragSidemenuItem) {
        if (from.id === to.id) return;
        if (from.groupId === to.groupId) {
            const group = await this.createGroupWithEntity(`New group ${this.groups.length + 1}`, [from.id, to.id]);
            Tracker.trackEvent('Group', { Type: 'Create', ObjectId: group.id});
        } else {
            const intent = this.getEntityById(from.id);
            if (intent) {
                intent.entity_group_id = to.groupId;
                await this.updateEntity(intent);
            }
        }
    }
}
