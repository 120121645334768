import { Trigger } from './Trigger';
import { action, makeObservable, observable } from 'mobx';
import { Entity } from '@/app/entities/models/entity';
import { Node } from '../Node';
import { Reaction } from '../reactions/Reaction';
import { State } from '../State';

export class EntityTrigger extends Trigger {
    static className = 'EntityTrigger';
    @observable entity?: Pick<Entity, 'id'> & Pick<Entity, 'name'>;
    /**
     * @deprecated Не используется в интерфейсе, LIACORE-233
     */
    @observable noEntityNext: State | Reaction | null = null;

    constructor() {
        super();
        makeObservable(this);
    }

    static validate(trigger: EntityTrigger) {
        if (!trigger.entity) {
            return { isValid: false, error: 'Отсутствует выбранная сущность' };
        } else {
            return { isValid: true };
        }
    }

    @action.bound insertNotEntityNext = (newNext: State | Reaction) => {
        if (!this.noEntityNext) {
            this.noEntityNext = newNext;
            newNext.parent = this;
        } else {
            newNext.parent = this.noEntityNext.parent;
            this.noEntityNext.parent = newNext;
            newNext.next = this.noEntityNext;
            this.noEntityNext = newNext;
        }
    }

    @action.bound remove(child: Node) {
        if (this.next === child) {
            super.remove(child);
        } else if (this.noEntityNext === child) {
            child.parent = null;
            this.noEntityNext = child.next as (State | Reaction | null);
            if (this.noEntityNext) {
                this.noEntityNext.parent = this;
            }
        }
    }
}
