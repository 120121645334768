import React, { FC, useEffect, useState } from 'react';
import cn from './VariablesPage.module.scss';
import { Permission, UserStore } from '@/core/stores/user.store';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '@/core/stores/project.store';
import { AppStore } from '../stores/app.store';
import { useTranslation } from 'react-i18next';
import { PermissionsView } from '@/app/permissions/Permissions';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Input, Space, Button, Select, Spin, Empty } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { VariablesStore } from '@/app/variables/variables.store';
import cns from 'classnames';

type ProjectSettingsProps = {
    user?: UserStore;
    projectStore?: ProjectStore;
    appStore?: AppStore;
}

const titlesByState: Partial<Record<SaveButtonState, string>> = {
    [SaveButtonState.default]: 'settings.save',
    [SaveButtonState.process]: 'actions.saving',
    [SaveButtonState.saved]: 'actions.saved'
};
export const VariablesPage: FC<ProjectSettingsProps> = inject('user', 'projectStore')(observer(({
                                                                                                                user,
                                                                                                                projectStore

                                                                                                            }) => {

    const [store] = useState(() => new VariablesStore(projectStore, user));
    const {t} = useTranslation();
    const [saveState, setSaveState] = useState(SaveButtonState.default);

    useEffect(() => {
        store.getVariables();
    }, []);

    const save = async () => {
        setSaveState(SaveButtonState.process);
        await store.saveVariables();
        setSaveState(SaveButtonState.saved);
        setTimeout(() => setSaveState(SaveButtonState.default), SaveButtonWaitingToDefaultTimeout);
    };

    return <Page actionMenu={
        <>
            <ActionsMenu right={<Space size={[12, 0]} wrap>
                <PermissionsView permission={Permission.EDIT_PROJECT_SETTINGS}>
                    <SaveButton onClick={save}
                                state={saveState}
                                titlesByState={titlesByState}/>
                </PermissionsView>
            </Space>}/>
        </>
    }>
        {store.isLoaded && <Button type={'primary'} onClick={() => store.addVariable()}>Добавить переменную</Button>}
        {store.variables.length === 0 && store.isLoaded &&
            <div className={cn.fullSizeWrapper}><Empty description={'Добавьте первую переменную в проект'}/></div>}
        {!store.isLoaded && <div className={cn.fullSizeWrapper}><Spin size={'large'}/></div>}
        {store.variables.length !== 0 && store.isLoaded && <div className={cn.variablesTable}>
            <div className={cn.variablesTableHeader}>
                <div className={cn.variablesTableHeaderCell}>Название <div className={cn.delimetr}></div></div>
                <div className={cn.variablesTableHeaderCell}>Значение <div className={cn.delimetr}></div></div>
                <div className={cn.variablesTableHeaderCell}>Тип <div className={cn.delimetr}></div></div>
                {user.permissions.isEditProjectSettings &&
                    <div className={cn.variablesTableHeaderCell}>Приватность</div>}
            </div>
            <div className={cn.variablesTableBody}>
                {store.isAction && <div className={cn.variablesTableAction}>
                    <Spin size={'large'}/>
                </div>}
                {store.variables.map((variable, i) => <div className={cn.variablesRow} key={i}>
                    <div className={cn.variablesCell}>
                        <Input disabled={!user.permissions.isEditProjectSettings && variable.is_private}
                               className={cn.input} onChange={(e) => variable.name = e.target.value}
                               value={variable.name}/>
                    </div>
                    {!user.permissions.isEditProjectSettings && variable.is_private &&
                        <div className={cn.variablesCell}>
                            <Input disabled={true} className={cn.input}
                                   value={variable.value}/>
                        </div>}
                    {(!variable.is_private || user.permissions.isEditProjectSettings) &&
                        <div className={cn.variablesCell}>
                            {variable.type === 'boolean' &&
                                <Select className={cn.input} defaultValue={true} value={variable.value}
                                        onChange={(value) => variable.value = value}
                                        options={[{label: 'True', value: true}, {label: 'False', value: false}]}/>}
                            {variable.type === 'string' &&
                                <Input className={cn.input} onChange={(e) => variable.value = e.target.value}
                                       value={variable.value}/>}
                            {variable.type === 'number' &&
                                <Input className={cn.input} type="number"
                                       onChange={(e) => variable.value = +e.target.value}
                                       value={variable.value}/>}
                        </div>
                    }
                    <div className={cn.variablesCell}>
                        <Select
                            value={variable.type}
                            disabled={!user.permissions.isEditProjectSettings && variable.is_private}
                            onChange={(value) => {
                                variable.type = value;
                                if (value === 'boolean') {
                                    variable.value = true;
                                } else {
                                    variable.value = undefined;
                                }
                            }}
                            options={[{label: 'Число', value: 'number'}, {
                                label: 'Строка',
                                value: 'string'
                            }, {label: 'Булеан', value: 'boolean'}]}
                        />
                    </div>

                    {/*{user.permissions.isEditProjectSettings && <div className={cn.variablesCell}>*/}
                    {/*    <Select*/}
                    {/*        value={variable.is_private}*/}
                    {/*        onChange={(value) => variable.is_private = value}*/}
                    {/*        options={[{label: 'Приватное', value: true}, {*/}
                    {/*            label: 'Открытое',*/}
                    {/*            value: false*/}
                    {/*        }]}*/}
                    {/*    />*/}
                    {/*</div>}*/}

                    <div className={cns(cn.variablesCell, cn.variablesActions)}>
                        {user.permissions.isEditProjectSettings && <Select
                            value={variable.is_private}
                            onChange={(value) => variable.is_private = value}
                            options={[{label: 'Приватное', value: true}, {
                                label: 'Открытое',
                                value: false
                            }]}
                        />}

                        <ConfirmDelete title={t('actions.delete_variable')}
                                       question={t('actions.delete_element', {name: variable.name})}
                                       onConfirm={() => store.deleteVariable(variable)}>
                            <Button disabled={!user.permissions.isEditProjectSettings && variable.is_private}
                                    icon={<DeleteOutlined/>}/>
                        </ConfirmDelete>

                    </div>

                </div>)}
            </div>
        </div>
        }
    </Page>
}))
