import { API } from '@/common/api';
import { Project } from '@/common/models/project';
import { ChatEvent } from './models/chat-event';
import { notification } from 'antd';

interface TestResponse {
    incoming_event: ChatEvent;
    outcoming_events: ChatEvent[];
}

export class ChatApi extends API {
    static async test(project: Project, user_id: string, message: string, facts: any): Promise<TestResponse> {
        try {
            return await ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
                type: 'text', params: { text: message }, user_id, facts
            })
        } catch(e) {
            // @ts-ignore
            if (e.status_code === 400) {
                notification.error({
                    message: 'В проекте должно быть добавлено хотя бы одно намерение.',
                    duration: 3
                });
            }
        }
    }

    static async sendStartEvent(project: Project, user_id: string): Promise<TestResponse> {
        try {
            return await ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
                type: 'start', params: { }, user_id
            })
        } catch(e) {
            // @ts-ignore
            if (e.status_code === 400) {
                notification.error({
                    message: 'В проекте должно быть добавлено хотя бы одно намерение.',
                    duration: 3
                });
            }
        }
    }

    static async sendIntent(project: Project, user_id: string, intent_id: string, facts?: Record<string, any>) {
        try {
            return await ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
                type: 'intent', params: { intent_id }, user_id, facts
            })
        } catch(e) {
            // @ts-ignore
            if (e.status_code === 400) {
                notification.error({
                    message: 'В проекте должно быть добавлено хотя бы одно намерение.',
                    duration: 3
                });
            }
        }
    }

    static async sendQA(project: Project, user_id: string, intent_id: string, facts?: Record<string, any>) {
        try {
            return await ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
                type: 'intent', params: { intent_id }, user_id, facts
            })
        } catch(e) {
            // @ts-ignore
            if (e.status_code === 400) {
                notification.error({
                    message: 'В проекте должно быть добавлено хотя бы одно намерение.',
                    duration: 3
                });
            }
        }
    }

    static async sendLocation(project: Project, user_id: string, lat: number, lng: number, formatted_address?: string): Promise<TestResponse> {
        try {
            return await ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
                type: 'location', params: {
                    formatted_address,
                    location: {
                        lat,
                        lng
                    }
                }, user_id
            })
        } catch(e) {
            // @ts-ignore
            if (e.status_code === 400) {
                notification.error({
                    message: 'В проекте должно быть добавлено хотя бы одно намерение.',
                    duration: 3
                });
            }
        }
    }
}
