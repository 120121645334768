import React, { FC, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { EntityStore } from '../entity.store';
import { EntityHttpRequest } from './entities/EntityHttpRequest';
import { EntityRegExp } from './entities/EntityRegExp';
import { EntityLocation } from './entities/EntityLocation';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { EntityKeywords } from './entities/EntityKeywords';
import cn from './Entity.module.scss';
import { EditableText } from '@/common/components/EditableText';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '../../chat/components/TestChat';
import { RightMenu } from '../../components/right-menu/RightMenu';
import { useTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { UserStore } from '@/core/stores/user.store';
import { Select, Space, Form, Button } from 'antd';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { DeleteOutlined } from '@ant-design/icons';
import { EntityEditStore } from '@/app/entities/entity-edit.store';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom/dist';
import { ConfirmLeave } from '@/common/components/ConfirmLeave/ConfirmLeave';

type EntityEditProps = {
    entityStore?: EntityStore;
    user?: UserStore;
}
export const Entity: FC<EntityEditProps> = inject('entityStore', 'user')(observer(({user, entityStore}) => {
    const [editStore] = useState(() => new EntityEditStore());
    const {t} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const desc = editStore.extractors.find(extractor => editStore.entity.extractor_type === extractor.value)?.description;

    useEffect(() => {
        editStore.selectedExtractorKey = undefined;

        const {id} = params;

        editStore.extractors = entityStore.extractors
            .filter(extractor => id !== 'new' ? true : extractor.is_active)
            .map(extractor => {
                return {
                    label: extractor.name,
                    value: extractor.key,
                    description: extractor.description
                };
            });


        if (id !== 'new') {
            editStore.entity = entityStore.getEntityById(+id);
            editStore.selectedExtractorKey = editStore.extractors.find(extractor => editStore.entity.extractor_type === extractor.value);
            const path = `/app/${params.projectId}/entities/${id}`;

            if (!editStore.entity) {
                navigate(path, {replace: true});
            }
            editStore.setPrevState(editStore.entity);
        } else {
            editStore.entity = {
                name: 'New entity',
                extractor_type: undefined
            };
            editStore.setPrevState(editStore.entity);
        }
    }, [location]);

    const onChangeSelectedEntity = (extractor: any) => {

        editStore.entityModelToSave = {};
        if (extractor) {
            editStore.selectedExtractorKey = extractor;
            editStore.entity.extractor_type = extractor.value;
        }

    };
    
    const deleteEntity = async () => {
        Tracker.trackEvent('Edit', {Object: 'entity', Type: 'delete', ObjectId: editStore.entity.id});
        await entityStore!.removeEntity(editStore.entity);
        navigate(`/app/${params.projectId}/entities`, {replace: true});
    };

    const saveEntity = async () => {
        Tracker.trackEvent('Save', {Object: 'entity', ObjectId: editStore.entity.id});

        if (!editStore.selectedExtractorKey?.value) {
            editStore.saveState = SaveButtonState.error;
            setTimeout(() => {
                editStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);

            return;
        }

        if (editStore.selectedExtractorKey?.value === 'keywords') {
            const isInValid = editStore.entityModelToSave.keywords?.some((keyword: any) => {
                    if (!keyword.words.length) {
                        keyword.invalid = true;
                        return true;
                    }
                    return false;
                });

            if (isInValid) {
                editStore.entity.extractor_params = editStore.entityModelToSave;
                return false;
            }
        }

        editStore.entity.extractor_params = editStore.entityModelToSave;
        editStore.saveState = SaveButtonState.process;

        try {
            editStore.entity = await entityStore.saveEntity(editStore.entity);
            editStore.saveState = SaveButtonState.saved;
            editStore.setPrevState(editStore.entity);
            navigate(`/app/${params.projectId}/entities/${editStore.entity.id.toString()}`, {replace: true});
        } catch (e) {
            editStore.saveState = SaveButtonState.error;
        } finally {
            setTimeout(() => {
                editStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
        }
    };

    const onEdit = (value: string) => {
        editStore.entity.name = value;
        if (editStore.entity.id) {
            entityStore.patchEntity({name: editStore.entity.name, id: editStore.entity.id});
        }
    };

    const onChangeEntityModel = (modelToSave: any) => {
        editStore.entityModelToSave = modelToSave;
    };

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            editStore.isChanged && currentLocation.pathname !== nextLocation.pathname
    );

    if (!editStore.entity) {
        return '';
    }

    return <Page actionMenu={
        user.permissions.isEditEntities && <ActionsMenu right={
            <Space size={[12, 0]} wrap>
                <ConfirmDelete title={t('actions.delete_entity')}
                               question={t('actions.delete_element', {name: editStore.entity.name})}
                               onConfirm={() => deleteEntity()}>
                    <Button title="Удалить" icon={<DeleteOutlined/>}/>
                </ConfirmDelete>

                <SaveButton onClick={saveEntity} state={editStore.saveState} titlesByState={editStore.titlesByState}/>
            </Space>
        }/>
    } rightBar={<RightMenu chat={<TestChat/>}/>}>
        {blocker.state === 'blocked' ? (
            <ConfirmLeave onConfirm={() => {
                entityStore.clearEntity(editStore.entity);
                Object.assign(editStore.entity, editStore.prevState);
                blocker.proceed();
            }} onCancel={blocker.reset}/>
        ) : null}

        <div className={cn.header}>
            <EditableText className={cn.entityTitle} text={editStore.entity.name}
                          onEdit={onEdit}
                          editable={user.permissions.isEditEntities}/>
        </div>
        <div className={cn.entitySubtitle}>{t('entities.select_entity_type')}</div>

        <Form.Item>
            <Select
                style={{width: '100%'}}
                placeholder={t('entities.search_an_entity')}
                value={editStore.selectedExtractorKey}
                labelInValue
                onChange={onChangeSelectedEntity}
                options={editStore.extractors}

            >
                {/* {editStore.extractors.map(extractor => <Select.Option key={extractor.value} value={extractor.value}
                                                                      label={extractor.label}>
                    <Space direction="vertical" size="small">
                        <span>{extractor.label}</span>

                        <span className={cn.entitySubtitle}>{t(extractor.description)}</span>
                    </Space>
                </Select.Option>)} */}
            </Select>
        </Form.Item>
        <div className={cn.description}>{ desc }</div>

        {editStore.selectedExtractorKey?.value === 'HTTPRequest' &&
            <EntityHttpRequest canEdit={user.permissions.isEditEntities}
                               modelToSave={editStore.entity.extractor_params}
                               onChange={onChangeEntityModel}/>}
        {editStore.selectedExtractorKey?.value === 'keywords' &&
            <EntityKeywords canEdit={user.permissions.isEditEntities}
                            modelToSave={editStore.entity.extractor_params}
                            onChange={onChangeEntityModel}/>}
        {editStore.selectedExtractorKey?.value === 'JSRegExp' &&
            <EntityRegExp canEdit={user.permissions.isEditEntities}
                          modelToSave={editStore.entity.extractor_params}
                          onChange={onChangeEntityModel}/>}
        {editStore.selectedExtractorKey?.value === 'location' &&
            <EntityLocation canEdit={user.permissions.isEditEntities}
                            modelToSave={editStore.entity.extractor_params}
                            onChange={onChangeEntityModel}/>}
    </Page>
}))
