import React, { FC, useEffect, useState } from 'react';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import classnames from 'classnames';
import cn from './Snippet.module.scss';
import { IntentItem } from '@/common/components/intent/IntentItem';
import { EditableText } from '@/common/components/EditableText';
import { inject, observer } from 'mobx-react';
import { SnippetStore } from '../../snippet.store';
import { observable } from 'mobx';
import { UncontrolledTooltip } from 'reactstrap';
import { Page } from '@/common/components/page/Page';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '@/app/chat/components/TestChat';
import { ChatStore } from '@/app/chat/chat.store';
import { RightMenu } from '@/app/components/right-menu/RightMenu';
import { useTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { UserStore } from '@/core/stores/user.store';
import { ErrorSign } from '@/common/svg-icons/ErrorSign';
import { SuccessSign } from '@/common/svg-icons/SuccessSign';
import CloseIcon from 'mdi-react/CloseIcon';
import { ExecutionSnippetView } from '@/app/snippets/models/execution-snippet';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Button, Space } from 'antd';
import { CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';
import Editor from '@monaco-editor/react';
import cns from 'classnames';
import { snippetTypes } from '@/app/snippets/components/snippet-edit/autocomplete-types';
import { useParams } from 'react-router-dom/dist';
import { useBlocker, useLocation, useNavigate } from 'react-router-dom';
import { SnippetEditStore } from '@/app/snippets/components/snippet-edit/snippet.store';
import { ConfirmLeave } from '@/common/components/ConfirmLeave/ConfirmLeave';
import localforage from 'localforage';

interface SnippetProps {
    snippetStore?: SnippetStore;
    chatStore?: ChatStore;
    user?: UserStore;
}

export const Snippet: FC<SnippetProps> = inject('snippetStore', 'user')(observer(({
                                                                                       snippetStore, user
                                                                                   }) => {
    const {t} = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [snippetEditStore] = useState(() => new SnippetEditStore());

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            snippetEditStore.isChanged && currentLocation.pathname !== nextLocation.pathname
    );

    const addSnippetListener = (element: HTMLButtonElement, code: string) => {
        if (element) {
            element.addEventListener('dragstart', (ev) => {
                (ev.target as HTMLSpanElement).classList.add(cn.dragStart);
                ev.dataTransfer.setData('text', code);
            });

            element.addEventListener('dragend', function (ev: Event) {
                (ev.target as HTMLSpanElement).classList.remove(cn.dragStart);
            });
        }
    }

    useEffect(() => {
        snippetEditStore.snippets = snippetStore.jsTemplates.map(template => Object.assign({ref: null}, template));
    }, []);

    useEffect(() => {
        if (params.id !== 'new') {
            localforage.setItem('currentSnippetId', params.id);
            snippetStore.getSnippetByIdFromServer(+params.id).then(result => {
                snippetEditStore.snippet = result;
                if (!snippetEditStore.snippet) {
                    navigate(`/app/${params.projectId}/snippets/new`, {replace: true});
                } else {
                    snippetEditStore.snippetCode = SnippetEditStore.getJavaScriptWrapper(snippetEditStore.snippet.value!['@JavaScript']);
                    snippetEditStore.prevState = snippetEditStore.snippetCode;
                }
            });
        } else {
            snippetEditStore.snippet = {
                name: 'New snippet',
                fact_group_id: 0,
                value: {
                    '@JavaScript': SnippetEditStore.getJavaScriptWrapper()
                }
            };

            snippetEditStore.snippetCode = SnippetEditStore.getJavaScriptWrapper();
            snippetEditStore.prevState = snippetEditStore.snippetCode;
        }

        snippetEditStore.executionResults.replace([]);
        snippetEditStore.isExecutionBarOpened = false;
    }, [location]);


    const onEdit = (value: string) => {
        snippetEditStore.snippet.name = value;
        if (snippetEditStore.snippet.id) {
            snippetStore!.patchSnippet({name: snippetEditStore.snippet.name, id: snippetEditStore.snippet.id});
        }
    };
    const saveSnippet = async () => {
        Tracker.trackEvent('Save', {Object: 'snippets', ObjectId: snippetEditStore.snippet.id});
        snippetEditStore.saveState = SaveButtonState.process;
        snippetEditStore.snippet.value!['@JavaScript'] = snippetEditStore.codeLines;

        try {
            snippetEditStore.snippet = await snippetStore.saveSnippet(snippetEditStore.snippet);
            snippetEditStore.saveState = SaveButtonState.saved;
            setTimeout(() => {
                snippetEditStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
            snippetEditStore.prevState = snippetEditStore.snippetCode;

            navigate(`/app/${params.projectId}/snippets/${snippetEditStore.snippet.id}`, {replace: true});
        } catch (e) {
            snippetEditStore.saveState = SaveButtonState.error;
            setTimeout(() => {
                snippetEditStore.saveState = SaveButtonState.default;
            }, 1000);
        }
    };
    const playSnippet = async () => {
        snippetEditStore.isExecutionBarOpened = true;
        const factsString = await snippetStore.getFacts();
        const facts = JSON.parse(factsString) || {};
        const executionResult = observable({loading: true} as ExecutionSnippetView);
        snippetEditStore.executionResults.unshift(executionResult);
        const result = await snippetStore.executeSnippet({
            code: snippetEditStore.codeLines,
            facts
        });
        executionResult.error = result.error;
        executionResult.result = result.error ? result.error : SnippetEditStore.parseExecutionResult(result.result);
        if (!result.error) {
            executionResult.events = result.events;
            executionResult.facts = Object.keys(result.facts).map(key => ({[key]: result.facts[key]}));
        }
        executionResult.loading = false;
    };

    const deleteSnippet = async () => {
        Tracker.trackEvent('Edit', {Object: 'snippets', Type: 'delete', ObjectId: snippetEditStore.snippet.id});
        await snippetStore.removeSnippet(snippetEditStore.snippet);
        navigate(`/app/${params.projectId}/snippets`, {replace: true});
    };
    const closeBar = () => {
        snippetEditStore.isExecutionBarOpened = false;
    }

    const handleEditorDidMount = (editor: any, monaco: any) => {
        monaco.languages.typescript.javascriptDefaults.addExtraLib(snippetTypes)
    }

    return <Page className={cn.page} rightBar={
        <RightMenu
            content={user.permissions.isEditFacts && <div className={cn.snippetWrapper}>
                <div className={cn.snippetTitle}>{t('snippets.snippets')}</div>
                <div className={cn.snippetSubtitle}>{t('snippets.drag_and_drop_help')}</div>
                <div className={cn.snippetList}>
                    {snippetEditStore.snippets.map((snippet, i) => <React.Fragment key={i}>
                        <IntentItem className={cn.snippet}
                                    id={`intentItem${snippet.id}`}
                                    draggable={true}
                                    intentRef={(element) => addSnippetListener(element, snippet.code)}
                                    additional={snippet.code}
                                    name={snippet.name}/>
                        <UncontrolledTooltip placement="top" target={`intentItem${snippet.id}`}>
                            {snippet.description}
                        </UncontrolledTooltip>
                    </React.Fragment>)}
                </div>
            </div>}
            contentMaxHeight={'470px'}
            chat={<TestChat/>}
        />
    } actionMenu={user.permissions.isEditFacts && <ActionsMenu right={
        <Space size={[12, 0]} wrap>
            <ConfirmDelete title={t('actions.delete_snippet')}
                           question={t('actions.delete_element', {name: snippetEditStore.snippet.name})}
                           onConfirm={() => deleteSnippet()}>
                <Button title="Удалить" icon={<DeleteOutlined/>}/>
            </ConfirmDelete>
            <Button title="Запустить" onClick={playSnippet} icon={<CaretRightOutlined/>}/>
            <SaveButton onClick={saveSnippet} state={snippetEditStore.saveState}
                        titlesByState={snippetEditStore.titlesByState}/>
        </Space>
    }/>}>

        {blocker.state === 'blocked' ? (
            <ConfirmLeave onConfirm={blocker.proceed} onCancel={blocker.reset}/>
        ) : null}

        <div className={cn.header}>
            <EditableText className={cn.editableTitle} text={snippetEditStore.snippet.name} onEdit={onEdit}
                          editable={user.permissions.isEditFacts}/>
        </div>
        <div className={cn.editorWrapper}>
            <Editor onMount={handleEditorDidMount}
                    className={cns(cn.aceEditor, snippetEditStore.isExecutionBarOpened && cn.aceEditorExecutionOpened)}
                    options={{
                        minimap: {
                            enabled: false,
                        },
                        readOnly: !user.permissions.isEditFacts
                    }} onChange={(value) => {
                snippetEditStore.snippetCode = value;
            }} height="calc(100vh - 225px)" defaultLanguage="javascript" value={snippetEditStore.snippetCode}
                    defaultValue={snippetEditStore.snippetCode}/>
        </div>
        <div className={classnames(!snippetEditStore.isExecutionBarOpened && cn.executorHide, cn.executor)}>
            <CloseIcon onClick={() => closeBar()} className={cn.closeIcon}/>
            {snippetEditStore.executionResults.map((executionResult, index) => {
                return <div className={cn.executorItem} key={index}>
                    {executionResult.error ? <ErrorSign className={cn.executionIcon}/> : <SuccessSign
                        className={classnames(cn.executionIcon, cn.executionSuccessIcon, executionResult.loading && cn.loadingExecution)}/>}
                    <div className={cn.executionResult}>

                        <div>{executionResult.error ? t('snippets.error') : t('snippets.result')}: {executionResult.loading ? t('snippets.execution') : executionResult.result}</div>
                        {!executionResult.loading && !executionResult.error && <div>
                            <div>Events:
                                {!executionResult.events.length && '-'}
                                {executionResult.events && executionResult.events.map((event: any, index: number) =>
                                    <div className={cn.executionEventFact} key={index}>- {JSON.stringify(event)}</div>)}
                            </div>
                            <div>New facts:
                                {!executionResult.facts.length && '-'}
                                {executionResult.facts && executionResult.facts.map((event: any, index: number) => <div
                                    className={cn.executionEventFact} key={index}>- {JSON.stringify(event)}</div>)}
                            </div>
                        </div>}
                    </div>

                </div>
            })}
        </div>
    </Page>;
}));
