import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { RootStore } from '../../core/stores/root.store';
import { Group } from '@/common/models/group';
import { downloadFile } from '@/common/utils/download-file';
import { Pagination } from '@/common/models/pagination';
import { Intent } from './models/Intent';
import { IntentAPI } from './intent.api';
import { LoadableStore } from '@/common/store';
import { Tracker } from '@/core/analytics/tracker';
import { DragSidemenuItem } from '@/app/components/sidebar/models/drag-sidemenu-item';
import { createLoadablePromise } from '@/common/utils/loadable-promise';

interface PaginationIntent {
    end?: boolean;
}

export class IntentStore extends LoadableStore {
    intentPageSize = 1000;
    @observable _intents: IObservableArray<Intent> = observable([]);
    @observable _groups: IObservableArray<Group> = observable([]);
    @observable isLoaded: boolean = false;
    _paginationGroups: Record<number, Pagination & PaginationIntent> = {};

    private loadablePromise = createLoadablePromise();

    get isLoadedPromise() {
        return this.loadablePromise.promise;
    }

    constructor(private rootStore: RootStore) {
        super();
        makeObservable(this);
    }

    @computed get paginationGroups() {
        return this._paginationGroups;
    }

    @computed get intents(): Array<Intent> {
        return this._intents;
    }

    @computed get groups() {
        return this._groups;
    }

    @computed
    get currentProject() {
        return this.rootStore.projectStore.choosenProject;
    }

    @computed
    get defaultGroup() {
        return this.groups[0];
    }

    @action.bound
    clearStore() {
        this._intents.replace([]);
        this._paginationGroups = {};
        this._groups.replace([]);
        this.isLoaded = false;
        this._load();
    }

    isEndPagination(group: Group) {
        if (!this._paginationGroups[group.id]) {
            return true;
        }
        return this._paginationGroups[group.id].end;
    }

    @action
    async _load() {
        const groups = await IntentAPI.getGroups(this.currentProject);
        this._groups.replace(groups);
        this._paginationGroups = {};
        if (groups.length) {
            const intents = await IntentAPI.getAll(this.currentProject, {
                page: 1,
                per_page: this.intentPageSize
            }, {
                // intent_group_id: groups[0].id!
            });
            runInAction(() => {
                this._intents.replace(intents.results);
            });
            this._paginationGroups[groups[0].id!] = {
                page: 2, per_page: this.intentPageSize, end: intents.current_page === intents.page_count
            };
        }

        this.loadablePromise.resolve('loaded');

        runInAction(() => {
            this.isLoaded = true;
        });
    }

    @action.bound
    async saveIntent(intent: Intent): Promise<Intent> {
        if (intent.id) {
            return this.updateIntent(intent);
        } else {
            return this.createIntent(intent);
        }
    }

    @action.bound
    async createIntent(intent: Intent) {
        if (!this.groups.length) {
            const group = await IntentAPI.createGroup(this.currentProject, {name: 'Default group'});
            this._paginationGroups[group.id!] = {end: true, page: 1, per_page: this.intentPageSize};
            this.groups.push(group);
        }

        const intentWithGroup = Object.assign({}, intent, {
            name: intent.name,
            intent_group_id: this.defaultGroup.id!
        });

        const _intent = await IntentAPI.createIntent(this.currentProject, intentWithGroup);
        this._intents.push(_intent);
        return _intent;
    }


    @action.bound
    patchIntent(intent: any) {
        return IntentAPI.patchIntent(this.currentProject, intent);
    }

    @action.bound
    async updateIntent(intent: Intent) {
        const result = await IntentAPI.updateIntent(this.currentProject, intent);
        let _intent = this.intents.find(i => i.id === result.id)!;
        if (_intent)
            Object.assign(_intent, result);
        else
            _intent = {...result};
        return _intent;
    }

    getIntentById(intentId: number) {
        return this._intents.find(_intent => _intent.id === intentId);
    }

    async getIntentWithoutPush(intentId: number) {
        return IntentAPI.getIntentById(this.currentProject, intentId);
    }

    async getIntentByIdAsync(intentId: number) {
        const exist = this.intents.find(i => i.id === intentId);
        if (exist) {
            return exist;
        }
        const intent = await IntentAPI.getIntentById(this.currentProject, intentId);
        this._intents.push(intent);
        return intent;
    }

    getGroupById(groupId: number) {
        return this._groups.find(_group => _group.id === groupId);
    }

    @action.bound
    async removeIntent(intent: Intent) {
        await IntentAPI.deleteIntent(this.currentProject, intent);
        const index = this.intents.findIndex(_intent => _intent.id === intent.id);
        this._intents.splice(index, 1);
    }

    @action.bound
    async createIntentgroupWithIntent(name = 'New group', intentIds: number[]) {
        const group = await IntentAPI.createGroup(this.currentProject, {name});
        this._groups.push(group);
        this.paginationGroups[group.id!] = {end: true, per_page: 0, page: 1};
        const updateIntent: Promise<Intent>[] = [];
        intentIds.forEach(
            id => {
                const intent = this._intents.find(_qa => _qa.id === id);
                if (intent) {
                    intent.intent_group_id = group.id!;
                    updateIntent.push(this.updateIntent(intent));
                }
            }
        );
        await Promise.all(updateIntent);
        return group;
    }

    @action
    async saveGroup(group: Group) {
        const updatedGroup = await IntentAPI.updateGroup(this.currentProject, group);
        const mergeGroup = this.getGroupById(group.id!);
        if (mergeGroup) {
            mergeGroup.name = updatedGroup.name;
        }
    }

    @action
    async removeGroup(groupId: number) {
        await IntentAPI.removeGroup(this.currentProject, groupId);
        const index = this.groups.findIndex(_group => _group.id === groupId);
        this.groups.splice(index, 1);
    }

    @action
    async getMoreElements(groupId: number) {
        if (!this.paginationGroups[groupId]) {
            this.paginationGroups[groupId] = {page: 1, per_page: this.intentPageSize};
        }
        try {
            const intents = await IntentAPI.getAll(this.currentProject, this.paginationGroups[groupId], {intent_group_id: groupId});
            this._intents.push(...intents.results.filter((intent: Intent) => {
                const _qa = this._intents.find(item => item.id === intent.id);
                return !_qa;
            }));
            if (intents.current_page === intents.page_count) {
                this.paginationGroups[groupId].end = true;
            } else {
                this.paginationGroups[groupId].page++;
            }
        } catch (e) {
            this.paginationGroups[groupId].end = true;
        }
    }

    @action
    async upload(files: File[], replace: boolean): Promise<void> {
        await IntentAPI.uploadIntent(this.currentProject, files[0], replace);
        return this.load();
    }

    @action
    async download(): Promise<void> {

        const response = await fetch(`/api/v1/projects/${this.currentProject.id}/intents-download`, {
            method: 'GET'
        });

        const text = await response.text();

        const file = response.headers.get('content-disposition')!.match('filename="(.*)"');
        const filename = file ? file[1] : 'export.csv';
        downloadFile(filename, text);
    }

    async fetchMore(ids: number[]): Promise<Intent[]> {
        return Promise.all(ids.map(id => this.getIntentByIdAsync(id)))
    }

    async replaceElementsInGroup(from: DragSidemenuItem, to: DragSidemenuItem) {
        if (from.id === to.id) return;
        if (from.groupId === to.groupId) {
            const group = await this.createIntentgroupWithIntent(`New group ${this.groups.length + 1}`, [from.id, to.id]);
            Tracker.trackEvent('Group', { Type: 'Create', ObjectId: group.id });
        } else {
            const intent = this.getIntentById(from.id);
            if (intent) {
                intent.intent_group_id = to.groupId;
                await this.updateIntent(intent);
            }
        }
    }
}
