import { action, computed, makeObservable, observable } from 'mobx';
import { QA as QAModel } from '@/app/qa/models/qa';
import { SaveButtonState, SaveButtonWaitingToDefaultTimeout } from '@/common/components/save-button/SaveButton';
import { ClipboardEvent, KeyboardEvent } from 'react';
import { Tracker } from '@/core/analytics/tracker';
import _ from 'lodash';
import { State } from '@/app/scripts/models/State';
import { v4 } from 'uuid';
import { IntentTrigger } from '@/app/scripts/models/triggers/IntentTrigger';
import { TextReaction } from '@/app/scripts/models/reactions/TextReactions';
import { Flow } from '@/app/scripts/models/Flow';
import { Script } from '@/app/scripts/models/Script';
import { MapperFactory } from '@/app/scripts/mappers/MapperFactory';
import { ScriptsStore } from '@/app/scripts/stores/scripts.store';
import { QAStore } from '@/app/qa/qa.store';
import { IntentStore } from '@/app/intents/intent.store';
import i18n from 'i18next';
import { isEqual, cloneDeep } from 'lodash-es';
export class QaEditStore {
    @observable newQuestionText = '';
    @observable addAnswerFormOpen = true;
    @observable isTitleEdited = false;
    // @ts-ignore
    @observable qa: QAModel;
    qaPrevState: QAModel;
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Partial<Record<SaveButtonState, string>> = {
        [SaveButtonState.default]: 'qa.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved'
    };
    @observable editIndex: number = -1;
    @observable editAnswerIndex: number = -1;

    @computed get isNewQA(): boolean {
        return !this.qa.id;
    }

    @computed get canSave(): boolean {
        return Boolean(this.qa.answers.length && this.qa.questions.length);
    }

    @computed get showQuestionForm() {
        return this.qa.answers.length > 0 ? this.addAnswerFormOpen : true;
    }
    constructor(private scriptsStore: ScriptsStore, private store: QAStore, private intentStore: IntentStore) {
        makeObservable(this);
    }

    @action.bound
    onEdit(value: string) {
        this.isTitleEdited = true;
        this.qa.name = value;
        if (this.qa.id) {
            this.store.patchQA({id: this.qa.id, name: this.qa.name});
        }
    }

    @action.bound
    onAddAnswer = (answer: string) => {
        this.qa.answers.push({text: answer});
        this.addAnswerFormOpen = false;
    }

    @action.bound
    onQuestionAdd() {
        if (!this.newQuestionText) {
            return;
        }

        this.editIndex = -1;

        if (!(this.qa.questions.length || this.isTitleEdited)) {
            this.qa.name = this.newQuestionText;
            this.isTitleEdited = true;
        }

        this.qa.questions.push({text: this.newQuestionText});
        this.newQuestionText = '';
    }

    @action.bound
    onPasteQa = (e: ClipboardEvent) => {
        const text = e.clipboardData.getData('Text');
        const list: string[] = text.split(/\r?\n/).filter(item => item);
        if (list.length > 1) {
            this.qa.questions.push(
                ...list.map(item => {
                    return {text: item}
                })
            );
            // TODO: how to fix firing change event after paste
            setTimeout(() => this.newQuestionText = '');
        }
    };

    @action.bound
    async onRemove() {
        Tracker.trackEvent('Edit', {Object: 'qa', Type: 'delete', ObjectId: this.qa.id});
        await this.store.removeQA(this.qa);
    }

    @action.bound
    async toggleArchive() {
        Tracker.trackEvent('Edit', {Object: 'qa', Type: 'archive', ObjectId: this.qa.id});
        await this.store.updateQA({...this.qa, is_active: !this.qa.is_active});
    }

    duplicate = async () => {
        const cloneQa = _.cloneDeep(this.qa);
        delete cloneQa.id;
        cloneQa.name = `${cloneQa.name} (clone)`;
        const newQa = await this.store!.saveQA(cloneQa);
        Tracker.trackEvent('Edit', {Object: 'qa', Type: 'duplicate', ObjectId: newQa.id});
    };

    async onChangeRoute(id: string) {
        this.isTitleEdited = false;
        this.editIndex = -1;
        if (id !== 'new') {
            this.qa = this.store.qas.find(item => item.id === +id)!;
            if (!this.qa) {
                // ??? типы?
                // @ts-ignore
                this.qa = await store.getQAByIdAsync(+match.params.id);
            }
            this.isTitleEdited = true;
            this.addAnswerFormOpen = false;
        } else {
            this.qa = {
                id: undefined,
                date_created: undefined,
                name: i18n.t('qa.new_answer'),
                answers: [],
                questions: [],
                qa_group_id: 0,
                is_active: true
            };
        }

        this.qaPrevState = cloneDeep(this.qa);
    }

    convertToFlow = async () => {
        Tracker.trackEvent('ConvertToFlow', {Object: 'qa', QaName: this.qa.name, ObjectId: this.qa.id});
        const intent = await this.intentStore.createIntent({
            name: this.qa.name!,
            intent_examples: this.qa.questions.map(qa => ({parts: [{text: qa.text}]})),
            is_active: true
        });

        const state = new State(v4(), 'State 1', []);

        const trigger = new IntentTrigger();
        trigger.intents = [{id: intent.id!}];

        const reaction = new TextReaction(v4(), 'Text 1');

        this.qa.answers.forEach(answer => {
            reaction.texts.push(answer.text);
        });

        trigger.next = reaction;
        state.triggers.push(trigger);

        const flow = new Flow();
        flow.next = state;


        const script = new Script(0, `${this.qa.name} Flow`, 'flow0.1', flow, true, new Date());
        // @ts-ignore
        const mapper = MapperFactory.buildMapper(script);
        const dto = mapper.toDTO(script);
        const createdFlow = await this.scriptsStore.save(dto);

        await this.toggleArchive();

        return createdFlow;
    };

    @action.bound
    async onSave() {
        Tracker.trackEvent('Save', {Object: 'qa', ObjectId: this.qa.id});
        this.editAnswerIndex = -1;
        this.editIndex = -1;
        this.qa.questions = this.qa.questions.filter(it => it.text?.length > 0);
        this.qa.answers = this.qa.answers.filter(it => it.text?.length > 0);
        this.saveState = SaveButtonState.process;
        const _qa = await this.store.saveQA(this.qa);
        this.saveState = SaveButtonState.saved;
        setTimeout(() => {
            this.saveState = SaveButtonState.default
        }, SaveButtonWaitingToDefaultTimeout);
        this.qa = _qa;
        this.qaPrevState = cloneDeep(_qa);

        return _qa;
    }

    get isChanged() {
        return !isEqual(this.qaPrevState, this.qa);
    }

    @action.bound
    onKeyDown(event: KeyboardEvent<HTMLDivElement>) {
        if (event.key === 'Enter' && !event.ctrlKey && !event.shiftKey) {
            event.preventDefault();
            this.editAnswerIndex = -1;
        }
    }
}
