import React, { FC, useEffect, useMemo, useState } from 'react';
import { generatePath, Route, Routes, useParams, useNavigate, useLocation } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { API } from './integrations/API';
import { Button, Space, Switch } from 'antd';
import cn from './ConnectPageContent.module.scss';
import { EditableText } from '@/common/components/EditableText';
import { Widget } from './integrations/Widget';
import { HelpDeskEddy } from './integrations/HelpDeskEddy';
import { Textback } from './integrations/Textback';
import { ConnectStore } from '../connect.store';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { TestChat } from '../../chat/components/TestChat';
import { useTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { Webim } from '@/app/connects/components/integrations/Webim';
import { UserStore } from '@/core/stores/user.store';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { Chat2Desk } from '@/app/connects/components/integrations/Chat2Desk';
import { Edna } from '@/app/connects/components/integrations/Edna';
import { Infobip } from '@/app/connects/components/integrations/Infobip';
import { Webim2 } from '@/app/connects/components/integrations/Webim2';
import { Livechat } from '@/app/connects/components/integrations/Livechat';
import { Facebook } from '@/app/connects/components/integrations/Facebook';
import { JivoChat } from '@/app/connects/components/integrations/JivoChat';
import { Usedesk } from '@/app/connects/components/integrations/Usedesk';
import { Omnidesk } from '@/app/connects/components/integrations/Omnidesk';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Intercom } from '@/app/connects/components/integrations/Intercom';
import { Sherlock } from '@/app/connects/components/integrations/Sherlock';
import { DeleteOutlined } from '@ant-design/icons';
import { Chatwoot } from '@/app/connects/components/integrations/Chatwoot';


interface ConnectPageContentProps {
    connectStore?: ConnectStore;
    user?: UserStore;
}

export class ChannelEditStore {
    currentEditForm: FormInstance;

    setCurrentEditForm(form: FormInstance) {
        this.currentEditForm = form;
    }
}

export const ConnectPageContent: FC<ConnectPageContentProps> = inject('user', 'connectStore')(observer(({user, connectStore}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [isOpenPopover, setIsOpenPopover] = useState(false);
    const { id } = params;

    useEffect(() => {
        connectStore.setChannel(+id);
    }, [location]);

    const isRequest = useMemo(() => connectStore.saveState === SaveButtonState.process, []);

    const deleteChannel = async () => {
        Tracker.trackEvent('Edit', {Object: 'connect', Type: 'delete', ObjectId: connectStore.currentChannel.id});
        const {projectId} = params;
        await connectStore.delete(connectStore.currentChannel);
        navigate(`/app/${projectId}/connect`, { replace: true });
    };

    const onFinish = async (values: any) => {
        Tracker.trackEvent('Save', {Object: 'connect', ObjectId: connectStore.currentChannel.id});
        const currentChannel = Object.assign(connectStore.currentChannel, {params: values});
        connectStore.saveState = SaveButtonState.process;

        try {
            const channel = await connectStore.saveChannel(currentChannel);

            connectStore.saveState = SaveButtonState.saved;
            setTimeout(() => {
                connectStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
            const {projectId} = params;
            const replacePath = generatePath('/app/:projectId/connect/channels/:id/:type', {
                projectId,
                id: channel.id.toString(),
                type: channel.channel_type
            });
            navigate(replacePath, { replace: true });
        } catch (e) {
            connectStore.saveState = SaveButtonState.error;
            setTimeout(() => {
                connectStore.saveState = SaveButtonState.default;
            }, SaveButtonWaitingToDefaultTimeout);
        }
    }


    const submit = () => {
        connectStore.channelEditStore.currentEditForm?.submit();
    };

    if (!connectStore.currentChannel) {
        return null;
    }

    return <Page actionMenu={
        user.permissions.isManageChannels && <ActionsMenu
            right={
                <Space size={[12, 0]} className="btn-group-sm" id="connect-page-right-actions">
                    <div className={cn.leftActionsMenu} id="disabledActivatePopover"
                         onMouseEnter={() => setIsOpenPopover(true)}
                         onMouseLeave={() => setIsOpenPopover(false)}
                    >
                        <Space size={[4, 0]} wrap>
                            {connectStore.currentChannel.is_active ? 'Включен' : 'Выключен'}
                            <Switch size={'small'} checked={connectStore.currentChannel.is_active}

                                    onChange={checked => connectStore.currentChannel.is_active = checked}
                                    disabled={connectStore.isDisabledPopoverVisible}
                            />
                        </Space>
                    </div>
                    <ConfirmDelete title={t('actions.delete_channel')}
                                   question={t('actions.delete_element', {name: connectStore.currentChannel.name})}
                                   onConfirm={() => deleteChannel()}>
                        <Button title="Удалить" icon={<DeleteOutlined/>}/>
                    </ConfirmDelete>
                    <SaveButton onClick={submit}
                                state={connectStore.saveState}
                                titlesByState={connectStore.titlesByState}/>
                </Space>
            }
        />
    } rightBar={
        <TestChat/>
    }>
        <div className={cn.header}>
            <EditableText className={cn.entityTitle} text={connectStore.currentChannel.name}
                          onEdit={value => connectStore.currentChannel.name = value}
                          editable={user.permissions.isManageChannels}/>
        </div>
        <div className={cn.pageConent}>
            <Routes>
                <Route path={'api'}
                       element={<API channelEditStore={connectStore.channelEditStore}
                                     onFinishForm={onFinish}
                                     channel={connectStore.currentChannel}/>}/>
                <Route path={'widget'}
                       element={<Widget channelEditStore={connectStore.channelEditStore}
                                        onFinish={onFinish}
                                        isRequest={isRequest}
                                        isManageChannels={user.permissions.isManageChannels}
                                        channel={connectStore.currentChannel}/>}/>
                <Route path={'webim'}
                       element={<Webim channelEditStore={connectStore.channelEditStore}
                                       onFinishForm={onFinish}
                                       channel={connectStore.currentChannel}/>}/>

                <Route path={'sherlock'}
                       element={<Sherlock channelEditStore={connectStore.channelEditStore}
                                          onFinish={onFinish}
                                          isRequest={isRequest}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                       />}/>

                <Route path={'intercom'}
                       element={<Intercom channelEditStore={connectStore.channelEditStore}
                                          onFinish={onFinish}
                                          isRequest={isRequest}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                       />}/>

                <Route path={'textback'}
                       element={<Textback channelEditStore={connectStore.channelEditStore}
                                          onFinish={onFinish}
                                          isRequest={isRequest}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          />}/>
                <Route path={'helpdeskeddy'}
                       element={<HelpDeskEddy isRequest={isRequest}
                                              channelEditStore={connectStore.channelEditStore}
                                              isManageChannels={user.permissions.isManageChannels}
                                              channel={connectStore.currentChannel}
                                              onFinish={onFinish}
                       />}/>
                <Route path={'chat2desk'}
                       element={<Chat2Desk isRequest={isRequest}
                                           connectStore={connectStore}
                                           channelEditStore={connectStore.channelEditStore}
                                           isManageChannels={user.permissions.isManageChannels}
                                           onFinish={onFinish}
                                           channel={connectStore.currentChannel}
                                           />}/>
                <Route path={'edna'}
                       element={<Edna isRequest={isRequest}
                                      channelEditStore={connectStore.channelEditStore}
                                      isManageChannels={user.permissions.isManageChannels}
                                      channel={connectStore.currentChannel}
                                      onFinish={onFinish}/>}/>
                <Route path={'infobip'}
                       element={<Infobip isRequest={isRequest}
                                         channelEditStore={connectStore.channelEditStore}
                                         isManageChannels={user.permissions.isManageChannels}
                                         channel={connectStore.currentChannel}
                                         onFinish={onFinish}/>}/>
                <Route path={'webim2'}
                       element={<Webim2 isRequest={isRequest}
                                        channelEditStore={connectStore.channelEditStore}
                                        isManageChannels={user.permissions.isManageChannels}
                                        channel={connectStore.currentChannel}
                                        onFinish={onFinish}
                       />}/>
                <Route path={'livechat'}
                       element={<Livechat isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}

                />
                <Route path={'fb'}
                       element={<Facebook isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}/>
                <Route path={'jivochat'}
                       element={<JivoChat isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}/>
                <Route path={'usedesk'}
                       element={<Usedesk isRequest={isRequest}
                                         channelEditStore={connectStore.channelEditStore}
                                         isManageChannels={user.permissions.isManageChannels}
                                         channel={connectStore.currentChannel}
                                         onFinish={onFinish}/>}/>

                <Route path={'omnidesk'}
                       element={<Omnidesk isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}/>

                <Route path={'chatwoot'}
                       element={<Chatwoot isRequest={isRequest}
                                          channelEditStore={connectStore.channelEditStore}
                                          isManageChannels={user.permissions.isManageChannels}
                                          channel={connectStore.currentChannel}
                                          onFinish={onFinish}/>}/>
            </Routes>
        </div>
        {user.permissions.isManageChannels &&
            <Popover isOpen={isOpenPopover && connectStore.isDisabledPopoverVisible}
                     placement="top-start"

                     target="disabledActivatePopover">
                <PopoverHeader>{t('connect.unable_to_activate')}</PopoverHeader>
                <PopoverBody>
                    <div>{t(connectStore.popoverText)}</div>
                </PopoverBody>
            </Popover>
        }
    </Page>
}));
